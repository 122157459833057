import React, { useState, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  Box,
  Heading,
  Text,
  Flex,
  Circle,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useInView } from "react-intersection-observer"

import { headerHeight } from "../../utils/constant"
import GatsbyImageBox from "@/components/GatsbyImageBox"

const query = graphql`
  {
    farmerImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "testimonial" }
        name: { regex: "/farmer-/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            backgroundColor: "transparent"
            transformOptions: { fit: COVER }
          )
        }
      }
    }
  }
`

const Testimonial = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)
  const farmerImages = data?.farmerImages?.nodes
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [swiperRef, setSwiperRef] = useState(null)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  })

  const slidesPerView = useBreakpointValue({ base: "1", lg: "2" })

  const reviews = useMemo(
    () => [
      { image: farmerImages[0] },
      { image: farmerImages[1] },
      { image: farmerImages[2] },
    ],
    [farmerImages]
  )

  return (
    <Box
      ref={ref}
      as="section"
      id="Testimonial"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
    >
      {(inView || typeof window === "undefined") && (
        <Box width="full" maxWidth="6xl" mx="auto" px="6" py="16">
          <Box textAlign="center">
            <Heading mb="6" color="ahuaOrange.80" fontWeight="400">
              {t("home-page.testimonial-section.heading")}
            </Heading>
            <Text color="gray.600" fontSize="1.2rem">
              {t("home-page.testimonial-section.subheading")}
            </Text>
          </Box>

          <Swiper
            spaceBetween={15}
            slidesPerView={slidesPerView}
            grabCursor
            onSlideChange={swiper => setActiveSlideIndex(swiper.activeIndex)}
            onSwiper={swiper => {
              setSwiperRef(swiper)
              console.log("swp")
            }}
          >
            {reviews.map((review, index) => (
              <SwiperSlide
                // What doesn't kill you makes you almost died
                // https://github.com/nolimits4web/swiper/issues/2372
                style={{
                  height: "auto",
                  display: "flex",
                  alignSelf: "stretch",
                  backgroundColor: "transparent",
                }}
                key={`review-${index + 1}`}
              >
                <Box
                  width="full"
                  mx="auto"
                  p={{ base: 6, md: 8 }}
                  backgroundColor="ahuaBlue.50"
                  borderRadius="xl"
                  boxShadow="lg"
                  mt={{ base: "6.5rem", sm: "10rem" }}
                >
                  <Flex
                    alignItems="center"
                    mb="8"
                    mt={{ base: "-5.325rem", sm: "-8.5rem" }}
                  >
                    <Box
                      mr={{ base: 2, md: 4 }}
                      width={{ base: "4.5rem", sm: "8.75rem" }}
                    >
                      <GatsbyImageBox
                        image={getImage(review.image)}
                        borderRadius="full"
                        borderColor="white"
                        background="white"
                        borderWidth={{ base: "0.25rem", sm: "0.5rem" }}
                        borderStyle="solid"
                        imgStyle={{ borderRadius: "100%" }}
                        alt={`yara farmer photo ${index + 1}`}
                      />
                    </Box>
                    <Box textAlign="left">
                      <Heading
                        fontSize={{ base: "0.75rem", sm: "1rem" }}
                        fontWeight="600"
                      >
                        {t(
                          `home-page.testimonial-section.reviews.${index}.name`
                        )}
                      </Heading>
                      <Text
                        fontSize={{ base: "0.625rem", sm: "1rem" }}
                        color="gray.600"
                        overflow="hidden"
                      >
                        {t(
                          `home-page.testimonial-section.reviews.${index}.title`
                        )}
                      </Text>
                    </Box>
                  </Flex>
                  <Text color="white">
                    &ldquo;
                    {t(
                      `home-page.testimonial-section.reviews.${index}.content`
                    )}
                    &rdquo;
                  </Text>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>

          <HStack justifyContent="center" spacing="3" mt="8">
            {Array.from(
              { length: reviews.length - slidesPerView + 1 },
              (_, index) => (
                <Circle
                  key={`review-pagination-bullet-${index}`}
                  size="0.75rem"
                  bg={
                    index === activeSlideIndex ? "ahuaBlue.100" : "ahuaBlue.40"
                  }
                  color="white"
                  cursor="pointer"
                  onClick={() => swiperRef.slideTo(index)}
                />
              )
            )}
          </HStack>
        </Box>
      )}
    </Box>
  )
}

export default Testimonial
